
  import Vue from 'vue';
  import * as api from '@/api';

  export default Vue.extend({
    props: {
      pvId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        records: [] as api.ProductAuditRecord[],
      };
    },
    // todo: watch pvId 的变动
    created() {
      this.fetchData();
    },
    methods: {
      async fetchData() {
        const res = await api.getProductAuditRecords({ pvId: this.pvId });
        if (!api.error(res)) {
          this.records = res.data;
        }
      },
    },
    filters: {
      title(record) {
        switch (record.auditStatus) {
          case 10:
            return '审核通过';
          default:
            return '审核不通过';
        }
      },
      desc(record) {
        switch (record.auditStatus) {
          case 10:
            return '产品审核通过';
          default:
            return record.description;
        }
      },
      statusIcon(status) {
        switch (status) {
          case 10:
            return 'el-icon-check';
          default:
            return '';
        }
      },
      statusType(status) {
        switch (status) {
          case 10:
            return 'primary';
          default:
            return '';
        }
      },
    },
  });
