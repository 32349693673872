
  import Vue from 'vue';
  import * as api from '@/api';
  import ProductStatus from '@/components/ProductStatus.vue';

  export default Vue.extend({
    name: 'VersionDetail',
    components: { ProductStatus },
    data() {
      return {
        versions: [] as api.ProductVersion[],
      };
    },
    async created() {
      this.$store.commit('breadcrumbList', [
        {
          name: '产品列表',
          path: '/product/list',
        },
        {
          name: '版本详情',
          path: '',
        },
      ]);
      await this.fetchData();
    },
    methods: {
      async setCurrentVersion(item) {
        if (item.status !== 30) return;
        const res = await api.setCurrentVersion({
          productId: this.$route.params.id,
          pvId: item.pvId,
        });
        if (!api.error(res)) {
          this.fetchData();
        }
      },
      async fetchData() {
        const productId = this.$route.params.id;
        const res = await api.getProductVersions({ productId });
        if (!api.error(res)) {
          this.versions = res.data;
        } else {
          this.$message({
            type: 'error',
            message: res.error.message,
          });
        }
      },
      editProduct(product) {
        this.$store.commit('productInfoModule/currentProductInfo', {
          productId: this.$route.params.id,
          pvId: product.pvId,
          status: product.status,
          statusTxt: product.statusTxt,
          name: product.name,
          readonly: Math.max(...this.versions.map((v) => v.version)) === product.version ? false : this.versions.some((v) => v.status !== 30),
        });
        this.$router.push({
          path: '/product/edit',
        });
      },
    },
  });
