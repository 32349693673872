
  import Vue from 'vue';
  import AuditDetail from '@/components/AuditDetail.vue';

  export default Vue.extend({
    props: {
      status: {
        type: Number,
        required: true,
      },
      statusTxt: {
        type: String,
        required: true,
      },
      pvId: {
        type: String,
        required: true,
      },
    },
    components: { AuditDetail },
    data() {
      return {
        auditDetailVisible: false,
      };
    },
    methods: {
      showAuditDetail() {
        // todo: 传参
        this.auditDetailVisible = true;
      },
      getStatusColor(status) {
        const map = {
          0: 'color-info',
          10: 'color-warning',
          20: 'color-error',
          30: 'color-success',
        };
        return map[status];
      },
    },
  });
